.CheckoutConfirm {
    display: flex;
    flex-direction: column;
}

.close {
    align-self: flex-end;
    margin-right: 10px;
}

.close:hover {
    cursor: pointer;
}

.checkContent {
    display: flex;
    justify-content: space-around;
}

.ConfirmOrder {
    display: block;
    background-color: #FF6738;
    color: #FFFFFF;
    align-self: center;
    padding: 2% 10%;
}

.ConfirmOrder:hover {
    cursor: pointer;
}

