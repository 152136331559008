.Dashboard {
    margin-left: 7.29%;
    margin-right: 7.29%;
    margin-top: 16px;
}

.DashboardHead {
    display: grid;
    grid-template-columns: 50% 50%;
    background-color: black;
    color: white;
}

.MaskImg {
    width: 100%;
    align-self: end;
}

.DashboardHead p{
    margin-top: unset;
    margin-bottom: unset;
    padding: 22px 0px 13px 27px;
}

.DashboardMain {
    display: grid;
    grid-template-columns: 73.75% 23.26%;
    gap: 2%;
    grid-template-rows: 40px 1fr;
}

.dashboardSearch {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 74.75% 23.26%;
    gap: 2%;
}

.SortArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.searchBox {
    display: flex;
    width: 100%;
    height: 20px;
    padding-top: 4%;
}

.searchBoxInput {
    width: 80%; 
    height: 101%;
    border: 1px solid #EFF2FF;
    background-color: #EFF2FF;
}

.searchimg {
    background-color: #FF6738;
    padding: 1%;
    width: 5.6%;
    height: 90%;
}

.searchimg:hover {
    cursor: pointer;
}

.courseCard {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 0.45fr 1fr 1fr 2fr 0.15fr;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    font-size: 18px;
    padding-bottom: 1%;
}

.greyBlock {
    width: 66%;
    background-color: #F2F2F2;
    height: 77%;
    margin-left: 9.55%;
    align-self: center;
}

.courseCard p{
    align-self: center;
}

.tagStyling {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    color: #FFFFFF;
}

.Tags {
    background-color: #E25B32;
    padding: 1%;
}

.starImg {
    height: 30%;
    align-self: center;
}

.addToCart {
    background-color: #FF6738;
    padding: 3%;
    text-align: center;
    margin-right: 20%;
    color: #FFFFFF;
}

.addToCart:hover {
    cursor: pointer;
}

.arrowImg {
    height: 12%;
    align-self: center;
}

.pageAdjust:hover {
    cursor: pointer;
}

.dashboard_cart {
    box-shadow: 0px 3px 6px #00000029;
    padding-left: 5%;
    padding-right: 5%;
}

.dash_cart_head {
    text-align: center; 
    padding-bottom: 2%;
}

.PriceArea {
    border-top: 1px solid #E0E0E0;
}

.checkout {
    color: #FF6738; 
    float: right;
}

.checkout:hover {
    cursor: pointer;
}