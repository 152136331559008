.Header {
    display: grid;
    grid-template-columns: 66.48% 11.6% 15.27% 3.03% 1fr;
    background-color: #F5F5F5;
    top: 0;
    position: sticky;
}

.hashedinLogo {
    width: 9.49%;
    height: 64.33%;
    padding-left: 1.59%;
    padding-top: 0.67%;
    padding-bottom: 0.5%;
}

.hashedinLogo:hover {
    cursor: pointer;
}

.course:hover {
    cursor: pointer;
}

.wish:hover {
    cursor: pointer;
}

.cart {
    padding-right: 1.67%;
    padding-top: 22%;
}

.cart:hover {
    cursor: pointer;
}

.profile {
    padding-top: 23%;
}

.profile:hover {
    cursor: pointer;
}