.Cart {
    margin-left: 7.29%;
    margin-right: 7.29%;
    margin-top: 16px;
}

.CartMain {
    display: grid;
    grid-template-columns: 73.75% 23.26%;
    gap: 2%;
}

.cartItem {
    display: grid;
    grid-template-columns: 1.5fr 6.5fr 2fr 1fr 0.5fr;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
}

.greyBlock {
    width: 66%;
    background-color: #F2F2F2;
    height: 77%;
    margin-left: 9.55%;
    align-self: center;
}

.cartItem p{
    align-self: center;
}

.Bin {
    align-self: center;
}

.Bin:hover {
    cursor: pointer;
}

.CheckoutSection {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E0E0E0;
    padding-left: 5%;
    padding-right: 5%;
    height: 150px;
}

.checkOut {
    background-color: #FF6738;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
}

.checkOut:hover {
    cursor: pointer;
}